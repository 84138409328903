<template>
  <form @input="$emit('input', formattedLetterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <div class="ml-40">
        <div v-for="(inner, j) in item" :key="j">
          <input type="text" v-model="inner.word" placeholder="Word" />
          <input type="text" v-model="inner.image" placeholder="Image link" />
        </div>
        <button type="button" @click="addItem(item)">
          + Inner
        </button>
        <button class="ml-10" type="button" @click="copyItem(item)">
          ][ Copy
        </button>
      </div>
    </div>

    <button
      type="button"
      @click="
        letterWords.push([
          {
            word: '',
            image: '',
          },
        ])
      "
    >
      + Add Item
    </button>

    <pre
      style="
        position: fixed;
        z-index: 100000;
        right: 10px;
        bottom: 10px;
        background: rgba(10, 0, 40, .9);
        color: #fff;
        font-size: 12px;
        padding: 8px;
        border-radius: 8px;
        line-height: 1;
        text-align: left;
        max-height: 90vh;
        overflow: auto;
        pointer-events: none;
      "
    >
      {{ this.formattedLetterWords }}
    </pre>
  </form>
</template>

<script>
// "letterWords": [
//     [
//       {
//         "word": "England",
//         "image": "/flags/England.svg"
//       },
//     ],
// ]
export default {
  data() {
    return {
      letterWords: [
        [
          {
            word: '',
            image: '',
          },
        ],
      ],
    };
  },
  computed: {
    formattedLetterWords() {
      let result = this.letterWords;
      return result;
    },
  },
  methods: {
    addItem(data) {
      data.push({
        word: '',
        image: '',
      });
    },
    copyItem(item) {
      console.log(item);
      let lastItem = item[item.length - 1]
      let itemCopy = Object.assign({}, lastItem)
      item.push(itemCopy)
    },
  },
};
</script>
